import React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'

import Layout from '../components/layout'
import projectStyles from './project.module.scss'
import Head from '../components/head'

const ProjectPage = () => {
  const data = useStaticQuery(graphql`
  query {
    allContentfulProject {
      edges {
        node {
          title
          slug
          description {
            raw
            references {
              file {
                url
              }
            }
          }
        }
      }
    }
  }
  `)

  return (
    <div>
      <Layout>
      <Head title="Projects" />
      <h1>Projects</h1>
      <ol className={projectStyles.posts}>
        {data.allContentfulProject.edges.map((edge) => {
          return (
            <li className={projectStyles.post}>
              <Link to={`/projects/${edge.node.slug}`}>
              <h2>{edge.node.title}</h2>
              </Link>
            </li>
          )
        })}
      </ol>
      </Layout>
    </div>
  )
}

export default ProjectPage